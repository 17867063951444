export function numberFormat(originalValue, value, decimals = 0, min = 0, max = 1000000) {
  let val = Number(value);
  if (val > max) {
    val = max;
  }
  if (val < min) {
    val = min;
  }
  const strVal = value.toString();
  if (isNaN(val)) {
    return originalValue;
  }
  if (decimals > 0) {
    if (strVal.indexOf(`${Math.floor(val)}.`) > -1) {
      if (strVal.split('.')[1].length > decimals) {
        return originalValue;
      }
      val = value;
    }
  }
  if (value === '') {
    val = '';
  }
  return val;
}

export function skuFormat(value) {
  // Alpha Numeric with Hyphens, Underscores,
  const val = value.replace(/[^\w\-.#]/g, '');
  // Regex here is: All letters (upper and lower), all Numbers,
  // Underscores, hyphens, Number Sign, periods
  return val;
}

export function flattenArrayofObj(array) {
  let result = [];
  array.forEach((element) => {
    const elementKeys = Object.keys(element);
    elementKeys.forEach((key) => {
      result = result.concat(element[key]);
    });
  });
  return result;
}

// Capitalize the first letter of a string
export function stringCapitalize(string) {
  const split = string.trim().split(' ');
  return split.map(function (str) {
    return `${str.charAt(0).toUpperCase() + str.slice(1)} `;
  });
}

export function stringCamelize(string) {
  const split = string.trim().toLowerCase().split(' ');
  return split
    .map(function (str, index) {
      return index === 0 ? str : str.charAt(0).toUpperCase() + str.slice(1);
    })
    .join('');
}

// react-tags healpers
export function tagAlreadyExists(tags, tag) {
  return tags.map((i) => i.text.toLowerCase()).indexOf(tag.text.toLowerCase()) > -1;
}

export function tagMap(tag, n) {
  return { id: n, text: tag };
}

export function tagUnpack(item) {
  return item.text;
}

export function parseRailsErrorsToString(response) {
  let errorString = '';
  for (const errorCategory of Object.keys(response.error)) {
    errorString += `${errorCategory} `;
    for (const error of response.error[errorCategory]) {
      errorString += `${error} `;
    }
  }
  return errorString;
}
