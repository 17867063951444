const ListingsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LISTING_CTA_PREMIUM_CLOSE':
      return {
        ...state,
        listingForPremiumModalCTA: false
      };
    case 'LISTING_CTA_PREMIUM_OPEN':
      return {
        ...state,
        listingForPremiumModalCTA: true
      };
    case 'LISTING_OPEN':
      return {
        ...state,
        listingForModal: action.payload.listing,
        listingModalType: action.payload.type,
        listingIndex: action.payload.listingIndex,
        listingModalProps: action.payload.dataModal,
        ...action.payload.options
      };
    case 'LISTING_CLOSE':
      return {
        ...state,
        listingForModal: null
      };
    case 'AGREE_MODAL_OPEN':
      return {
        ...state,
        noticeModalIsOpen: action.payload.noticeModalIsOpen
      };
    case 'AGREE_MODAL_CLOSE':
      return {
        ...state,
        noticeModalIsOpen: action.payload.noticeModalIsOpen
      };
    case 'ON_IMPORT_LIST_LISTING_IDS':
      return {
        ...state,
        importedList: action.payload.onImportListListingIDs
      };
    case 'ADDED_TO_IMPORTED_LIST':
      return {
        ...state,
        importedList: [...state.importedList, action.payload.listingID]
      };
    case 'REMOVED_FROM_IMPORTED_LIST':
      return {
        ...state,
        importedList: state.importedList.filter((id) => id !== action.payload.listingID)
      };
    default:
      return state;
  }
};

export function premiumCTAModalClosed(listing) {
  return {
    type: 'LISTING_CTA_PREMIUM_CLOSE'
  };
}

export function premiumCTAModalOpened(listing) {
  return {
    type: 'LISTING_CTA_PREMIUM_OPEN'
  };
}

export function showModal(listing, type, listingIndex, dataModal) {
  return {
    type: 'LISTING_OPEN',
    payload: { listing, type, listingIndex, dataModal }
  };
}

export function closeModal(listing) {
  return {
    type: 'LISTING_CLOSE',
    payload: null
  };
}

export function showNoticeModal() {
  return {
    type: 'AGREE_MODAL_OPEN',
    payload: { noticeModalIsOpen: true }
  };
}

export function closeNoticeModal() {
  return {
    type: 'AGREE_MODAL_CLOSE',
    payload: { noticeModalIsOpen: false }
  };
}

export function setListingIDsOnImportList(onImportListListingIDs) {
  return {
    type: 'ON_IMPORT_LIST_LISTING_IDS',
    payload: { onImportListListingIDs }
  };
}

export function addedToImportList(listingID) {
  return {
    type: 'ADDED_TO_IMPORTED_LIST',
    payload: { listingID }
  };
}

export function removedFromImportList(listingID) {
  return {
    type: 'REMOVED_FROM_IMPORTED_LIST',
    payload: { listingID }
  };
}

export default ListingsReducer;
