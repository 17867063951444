import { ApiBaseCall } from '../../../utils/apiCall';

const initialState = {
  isFetching: false,
  categories: []
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'listingsCategory.isFetching':
      return { ...state, isFetching: true };

    case 'listingsCategory.fetchSuccess':
      return { ...state, isFetching: false, categories: [...action.response] };

    case 'listingsCategory.fetchError':
      return { ...state, isFetching: false, ...action.response };

    default:
      return state;
  }
}

// Actions
export function getListingsCategory() {
  return (dispatch) => {
    dispatch({ type: 'listingsCategory.isFetching' });
    ApiBaseCall.get('/shared/listings_category')
      .then((response) => {
        dispatch({
          type: 'listingsCategory.fetchSuccess',
          response: response.json
        });
      })
      .catch((error) => {
        dispatch({ type: 'listingsCategory.fetchError', error });
      });
  };
}
