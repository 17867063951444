import ApiCall from '../../../utils/apiCall';
import { messageError, messageSuccess } from '../../../actions';

const initialState = {
  isFetching: false,
  url: ''
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'landingPageHeader.isFetching':
      return { ...state, isFetching: true };

    case 'landingPageHeader.success':
      return { ...state, isFetching: false, ...action.response };

    case 'landingPageHeader.fetchError':
      return { ...state, isFetching: false, error: action.response };

    default:
      return state;
  }
}

// Actions
export function uploadHeaderImage(attachment) {
  return (dispatch) => {
    dispatch({ type: 'landingPageHeader.isFetching' });
    ApiCall.post('/settings/landing_page_header', { attachment })
      .then((response) => {
        dispatch({
          type: 'landingPageHeader.success',
          response: response.json
        });
        dispatch(messageSuccess('Image Uploaded Successfully', false));
      })
      .catch((response) => {
        dispatch({
          type: 'landingPageHeader.fetchError',
          response: response.json.errors.attachment[0]
        });
        dispatch(messageError(response.json.errors.attachment[0], false));
      });
  };
}
