import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';
import AuthReducer from './auth';
import MessageReducer from './message';
import OrdersReducer from './orders';
import SettingsReducer from './settings';
import { SIGN_OUT_USER } from '../actions/constants';
import ListingsReducer from './listings';
import UiReducer from './ui';

// New Supplier App
import registration from '../newSupplierApp/module/registration';
import PaymentReducer from '../newSupplierApp/module/payment';
import landingPageHeader from '../newSupplierApp/module/settings/landingPageHeader';
import listingsCategory from '../newSupplierApp/module/shared/listingsCategory';
// UI
import currentModal from '../newSupplierApp/module/ui/currentModal';

// migrated from shared_components
const SharedComponentsReducer = combineReducers({
  listings: ListingsReducer,
  ui: UiReducer
});

const appReducer = combineReducers({
  form: FormReducer,
  message: MessageReducer,
  auth: AuthReducer,
  orders: OrdersReducer,
  settings: SettingsReducer,
  payment: PaymentReducer,
  registration,
  sharedComponents: SharedComponentsReducer,
  ui: combineReducers({
    modal: currentModal
  }),
  landingPageHeader,
  shared: combineReducers({ listingsCategory })
});

const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT_USER) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
