import ApiCall from '../../utils/apiCall';
import { messageSuccess, messageError } from '../../actions/index';

const initialState = {};

// Reducer
export default function action(state = initialState, action) {
  switch (action.type) {
    case 'payment.getStripeInformationSuccess':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
}

// Actions
// ! OLD
export function createStripeTokenAndSubmit(stripeData, submitData) {
  return function (dispatch) {
    const stripe = window.Stripe(process.env.NEXT_PUBLIC_STRIPE_KEY);
    return stripe
      .createToken('bank_account', stripeData)
      .then(function (result) {
        if (!result.token) {
          dispatch(messageError(result.error.message));
          return;
        }
        submitData.bank_token = result.token.id;
        dispatch(submitBankToken(submitData));
      })
      .catch((error) => {
        dispatch(messageError(error));
      });
  };
}

// ? NEW
export function createNewStripeTokenAndSubmit(stripeData, submitData) {
  return function (dispatch) {
    const stripe = window.Stripe(process.env.NEXT_PUBLIC_STRIPE_KEY2);
    return stripe
      .createToken('bank_account', stripeData)
      .then(function (result) {
        if (!result.token) {
          dispatch(messageError(result.error.message));
          return;
        }
        submitData.bank_token = result.token.id;
        dispatch(submitNewStripeBankToken(submitData));
      })
      .catch((error) => {
        dispatch(messageError(error));
      });
  };
}

// ! OLD
function submitBankToken(submitData) {
  return function (dispatch) {
    ApiCall.patch('/stripe_integrations/account', {
      on_stripe: submitData
    })
      .then(() => {
        dispatch(messageSuccess('Your bank account has been submitted successfully!'));
        dispatch(getStripeInformation());
      })
      .catch(({ json }) => {
        dispatch(messageError(json.error));
      });
  };
}

// ? NEW
function submitNewStripeBankToken(submitData) {
  return function (dispatch) {
    ApiCall.patch('/stripe_integrations/account', {
      account_source: 'new',
      on_stripe: submitData
    })
      .then(() => {
        dispatch(messageSuccess('Your bank account has been submitted successfully!'));
        dispatch(getNewStripeInformation());
      })
      .catch(({ json }) => {
        dispatch(messageError(json.error));
      });
  };
}

// ! OLD
export function updatePaymentInfo(submitData) {
  return function (dispatch) {
    return ApiCall.patch('/stripe_integrations/account', {
      on_stripe: submitData
    })
      .then(() => {
        dispatch(messageSuccess('Your bank account has been updated successfully!'));
        dispatch(getStripeInformation());
      })
      .catch(({ json }) => {
        dispatch(messageError(json.error));
        return json.error;
      });
  };
}

// ? NEW
export function updateNewStripePaymentInfo(submitData) {
  return function (dispatch) {
    return ApiCall.patch('/stripe_integrations/account', {
      account_source: 'new',
      on_stripe: submitData
    })
      .then(() => {
        dispatch(messageSuccess('Your bank account has been updated successfully!'));
        dispatch(getNewStripeInformation());
      })
      .catch(({ json }) => {
        dispatch(messageError(json.error));
        return json.error;
      });
  };
}

// ! OLD
export function getStripeInformation() {
  return function (dispatch) {
    return ApiCall.get('/stripe_integrations/account').then(({ status, json }) => {
      if (status === 200) {
        dispatch({
          type: 'payment.getStripeInformationSuccess',
          data: json.on_stripe
        });
      }
    });
  };
}

// ? NEW
export function getNewStripeInformation() {
  return function (dispatch) {
    return ApiCall.get('/stripe_integrations/account', {
      account_source: 'new'
    }).then(({ status, json }) => {
      if (status === 200) {
        dispatch({
          type: 'payment.getStripeInformationSuccess',
          data: json.on_stripe
        });
      }
    });
  };
}

// ! OLD
export function createAccount(payload, callback) {
  return function (dispatch) {
    return ApiCall.post('/stripe_integrations/account', payload)
      .then(() => {
        dispatch(getStripeInformation());
        dispatch(messageSuccess('Your bank account has been created successfully!'));
        callback();
      })
      .catch(({ json }) => {
        messageError(json);
        callback();
      });
  };
}

// ? NEW
export function createNewStripeAccount(payload, callback) {
  return function (dispatch) {
    return ApiCall.post('/stripe_integrations/account', payload)
      .then(() => {
        dispatch(getNewStripeInformation());
        dispatch(messageSuccess('Your bank account has been created successfully!'));
        callback();
      })
      .catch(({ json }) => {
        messageError(json);
        callback();
      });
  };
}

export function accountHostedSetup() {
  return function (dispatch) {
    ApiCall.post('/stripe_integrations/hosted_onboarding_link').then(({ json: { url } }) => {
      window.location.replace(url);
    });
  };
}
