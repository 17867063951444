import { useEffect } from 'react';
import Head from 'next/head';
import App from 'next/app';
import { ThemeProvider } from 'styled-components';
import { QueryClientProvider, QueryClient } from 'react-query';
import { appWithTranslation } from 'next-i18next';
import TagManager from 'react-gtm-module';
import 'react-toastify/dist/ReactToastify.css';
import '@shopify/polaris/dist/styles.css';

import { colors } from '../src/const/themes';
import { ReduxContextWrapper } from '../src/store';
import ContextProvider from '../src/providers/ContextProvider';

// import all old styles from src_old
import '../src_old/react-bootstrap-table-all.min.css';
import '../src_old/index.css';
import '../src_old/components/SignUp/style.css';
import '../src_old/components/PageFooter/style.css';
import '../src_old/shared_components/ListingModal/components/LazyLoadImage/style.scss';
import '../src_old/shared_components/components/BestSelling.css';
import '../src_old/shared_components/components/TopSupplier.css';
import '../src_old/components/Dashboard/ResourceList/ResourceListItem/style.css';
import '../src_old/components/Dashboard/style.css';
import '../src_old/components/ProductsListings/style.css';
import '../src_old/components/Search/style.css';
import '../src_old/components/ListingForm/style.css';
import '../src_old/components/Settings/style.css';
import '../src_old/newSupplierApp/component/sharedStyles.scss';
import '../src_old/shared_components/ListingModal/style.scss';
import '../src_old/shared_components/NoticeModal/style.scss';
import '../src_old/shared_components/stylesheet/main.scss';
import '../src_old/components/ContextPopover/style.css';
import '../src_old/components/ImageInput/style.css';
import '../src_old/components/ListingForm/VariationsTableForm/Fields/style.css';
import '../src_old/components/Settings/Shipping/style.css';

import '../src_old/components/DateRangePicker/style.css';
import '../src_old/components/Orders/DownloadOrdersCSVModal.css';
import '../src_old/components/Orders/Order/CancelOrderModal.css';
import '../src_old/components/Orders/Order/style.css';
import '../src_old/components/Orders/style.css';

import '../src_old/components/Navigation/s_c_bundle.css';
import '../src_old/components/Navigation/style.css';
import '../src_old/components/Terms/style.css';
import '../src_old/newSupplierApp/pages/IntroPage.css';
import '../src_old/newSupplierApp/pages/ThankYouPage.css';
import '../src_old/newSupplierApp/pages/registrationDetails/RegistrationForm.css';

import '../src_old/components/Settings/Account/style.css';
import '../src_old/components/Settings/Password/style.css';
import '../src_old/components/Settings/SettingsNav/style.css';
import '../src_old/newSupplierApp/component/NewStripeForm/shared/AccountInfoFields.css';
import '../src_old/newSupplierApp/component/NewStripeForm/shared/BankAccountFields.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  }
});

// eslint-disable-next-line react/prop-types
function SuppliersApp({ Component, pageProps }) {
  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_APP_ID });
  }, []);

  return (
    <>
      <Head>
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <script src="https://js.stripe.com/v3/" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
      </Head>
      <QueryClientProvider client={queryClient}>
        <ContextProvider>
          <ThemeProvider theme={{ colors }}>
            <Component {...pageProps} />
          </ThemeProvider>
        </ContextProvider>
      </QueryClientProvider>
    </>
  );
}

SuppliersApp.getInitialProps = async (appContext) => ({
  ...(await App.getInitialProps(appContext))
});

export default ReduxContextWrapper.withRedux(appWithTranslation(SuppliersApp));
