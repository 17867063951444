const initialState = {
  currentModal: null
};

// Reducer
export default function action(state = initialState, action) {
  switch (action.type) {
    case 'ui.currentModal.showModal':
      return {
        ...state,
        currentModal: action.payload.currentModal,
        data: action.payload.data
      };
    case 'ui.currentModal.closeModal':
      return {
        ...state,
        currentModal: action.payload.currentModal
      };
    default:
      return state;
  }
}

// Actions
export function showModal(currentModal, data) {
  return (dispatch) => {
    dispatch({
      type: 'ui.currentModal.showModal',
      payload: { currentModal, data }
    });
  };
}

export function closeModal() {
  return (dispatch) => {
    dispatch({
      type: 'ui.currentModal.closeModal',
      payload: { currentModal: null }
    });
  };
}
