import { createContext } from 'react';

const defaultState = {
  screenSize: {
    screenWidth: null,
    screenHeight: null
  }
};

const Context = createContext(defaultState);
export default Context;
