import {
  AUTH_USER,
  SIGN_IN_USER,
  AUTH_ERROR,
  AUTH_SUCCESS,
  OPEN_RESET_MODAL,
  CLOSE_RESET_MODAL
} from '../actions/constants';

const initialState = {
  authenticated: false,
  registered: false,
  error: null,
  success: null,
  resetModalIsOpen: false,
  settingsSaved: false,
  migratedFrom: false,
  hasIntegrations: false,
  showLoading: true
};

export default function action(state = initialState, action) {
  switch (action.type) {
    case OPEN_RESET_MODAL:
      return {
        ...state,
        resetModalIsOpen: true,
        error: null,
        showLoading: false
      };
    case CLOSE_RESET_MODAL:
      return {
        ...state,
        resetModalIsOpen: false,
        error: null,
        showLoading: false
      };
    case SIGN_IN_USER:
      return {
        ...state,
        authenticated: true,
        error: null,
        showLoading: false
      };
    case AUTH_USER:
      return {
        ...state,
        authenticated: true,
        error: null,
        migratedFrom: action.json.migrated_from,
        hasIntegrations: action.json.has_integrations,
        showLoading: false
      };
    case AUTH_ERROR:
      return {
        ...state,
        success: null,
        error: action.payload.error,
        showLoading: false
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        error: null,
        success: action.payload.message,
        showLoading: false
      };
    default:
      return state;
  }
}
