const initialState = {
  currentModal: null
};

const UiReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        ...state,
        currentModal: action.payload.currentModal,
        data: action.payload.data
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        currentModal: action.payload.currentModal
      };
    default:
      return state;
  }
};

export function showWrapperModal(currentModal, data) {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_MODAL',
      payload: { currentModal, data }
    });
  };
}

export function closeWrapperModal() {
  return (dispatch) => {
    dispatch({
      type: 'CLOSE_MODAL',
      payload: { currentModal: null }
    });
  };
}

export default UiReducer;
