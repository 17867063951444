import { useQuery } from 'react-query';
import axiosLib from 'axios';

// import api.js into itself for facilitating the test flows
// eslint-disable-next-line import/no-self-import
import * as api from './api';

const host = process.env.NEXT_PUBLIC_API_URL;

let axios;

// Creates a local shared instance of Axios if doesn't exist yet.
const checkAxiosInit = () => {
  if (!axios) {
    axios = axiosLib.create({
      timeout: 60000,
      headers: {
        common: {
          Accept: 'application/json',
          'content-type': 'application/json'
        }
      }
    });
  }

  if (axios && !axios.defaults.headers.common.Authorization) {
    const { common } = axios.defaults.headers;
    if (localStorage.getItem('auth_token')) {
      axios.defaults.headers.common = {
        ...common,
        Authorization: `Bearer ${localStorage.getItem('auth_token')}`
      };
    }
  }
};

const sendGet = async (path, params) => {
  checkAxiosInit();
  return axios.get(host + path, { params });
};

const sendPost = async (path, params, headers) => {
  checkAxiosInit();
  return axios.post(host + path, params, headers);
};

const sendPut = async (path, params) => {
  checkAxiosInit();
  return axios.put(host + path, params);
};
const sendPatch = async (path, params) => {
  checkAxiosInit();
  return axios.patch(host + path, params);
};

const sendDelete = async (path, params) => {
  checkAxiosInit();
  return axios.delete(host + path, params);
};

export const sendCSV = (path, data) => {
  const res = axiosLib.post(host + path, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('auth_token')}`
    }
  });
  return res;
};

export const signInSupplier = async (values) => {
  const data = await sendPost('/sign_in', values);
  return data;
};

export const signOutSuppplier = async () => {};

/* ******************************************************  */

export const fetchMyProducts = (params) => sendGet('/listings', params);

export const SEARCH_MY_PRODUCTS_QUERY = 'myProducts';
export const useMyProducts = ({
  queryKey = SEARCH_MY_PRODUCTS_QUERY,
  page = 1,
  query = '',
  column = '',
  direction = '',
  listing_category_id = '',
  tags = '',
  status = '',
  enabled = false
}) => {
  const params = {
    page,
    ...(query && { query }),
    ...(column && { column }),
    ...(direction && { direction }),
    ...(listing_category_id && { listing_category_id }),
    ...(tags && { tags }),
    ...(status && { status })
  };

  return useQuery([queryKey, page], () => api.fetchMyProducts(params), {
    keepPreviousData: true,
    enabled
  });
};

export const updateListingStatus = (id) => {
  return sendPatch(`/listings/${id}/toggle_activation_status`);
};

export const activateListingsInBulk = (ids) => {
  return sendPatch('/listings/bulk_activate_listings', { listing_ids: ids });
};

export const updateCategoryInBulk = (ids, categoryId) => {
  return sendPatch('/listings/bulk_update_listing_categories', {
    listing_ids: ids,
    listing_category_id: categoryId
  });
};

export const MERCHANT_CATEGORIES_QUERY = 'Categories';
export const fetchMerchantCategories = () => {
  return useQuery(MERCHANT_CATEGORIES_QUERY, () =>
    sendGet('/categories').then((res) => {
      return res.data;
    })
  );
};

export const fetchMyOrders = (params) => sendGet('/orders/v2_index', params);

export const SEARCH_MY_ORDERS_QUERY = 'myOrders';

export const useMyOrders = ({
  queryKey = SEARCH_MY_ORDERS_QUERY,
  query,
  page = 1,
  page_size,
  column = '',
  direction = '',
  fulfillment_status = '',
  delivery_status = '',
  start_date = '',
  end_date = '',
  enabled = false
}) => {
  const params = {
    ...(query && { query }),
    page,
    ...(page_size && { page_size }),
    ...(column && { sort_column: column }),
    ...(direction && { sort_direction: direction }),
    ...(delivery_status && { delivery_status }),
    ...(fulfillment_status && { fulfillment_status }),
    ...(start_date && { start_date }),
    ...(end_date && { end_date })
  };

  return useQuery([queryKey, page], () => api.fetchMyOrders(params), {
    keepPreviousData: true,
    enabled
  });
};

export const FETCH_ORDER_QUERY = 'fetchOrder';

export const useOrder = ({ orderId }) => {
  const queryKey = [FETCH_ORDER_QUERY, orderId];
  return useQuery(queryKey, () =>
    sendGet(`/orders/${orderId}/details`).then((res) => {
      return res.data;
    })
  );
};

export const cancelOrder = ({ orderId, reason }) =>
  sendPost(`/orders/${orderId}/cancel`, { cancel_reason: reason });

export const addTracking = ({ orderId, params }) =>
  sendPost(`/orders/${orderId}/create_tracking`, params);

export const processOrders = ({ queryString }) =>
  sendPatch(`/orders/set_to_processing?ids=${queryString}`);

export const getCSV = ({ queryString }) => sendGet(`/orders/bulk_export?ids=${queryString}`);

export const resetPassword = ({ email }) => sendGet('/password/new', { email });

export const importTrackingInfoInCsv = (data) => sendPost('/orders/create_tracking_bulk', data);

export const importSupplierListingsInCSV = (data) => sendCSV('/listings/upload_csv_file', data);

export const getQueueCount = () => sendGet(`/integrations/queue_count`);

export const FETCH_SETTINGS_QUERY = 'Settings';
export const useSettings = () => {
  return useQuery(FETCH_SETTINGS_QUERY, () =>
    sendGet('/settings').then((res) => {
      return res.data;
    })
  );
};

export const FETCH_CURRENCIES = 'Currencies';
export const useCurrencies = () => {
  return useQuery(FETCH_CURRENCIES, () =>
    sendGet('/currencies').then((res) => {
      return res.data;
    })
  );
};

export const saveSettings = (params) => sendPatch('/settings', params);
export const changePassword = ({ params }) => sendPatch('', params);

export const fetchVerifyAccountURL = () => sendPost('/stripe_integrations/hosted_onboarding_link');

export const PAYMENT_INFO_QUERY_KEY = 'paymentInfo';

export const getPaymentInfo = ({ params }) => {
  return useQuery(PAYMENT_INFO_QUERY_KEY, () =>
    sendGet('/stripe_integrations/account', params).then((res) => {
      return res.data;
    })
  );
};

export const PAYPAL_PAYMENT_INFO_QUERY_KEY = 'paypalPaymentInfo';

// PAYPAL
export const getPaypalPaymentInfo = () => {
  return useQuery(PAYPAL_PAYMENT_INFO_QUERY_KEY, () =>
    sendGet('/marketplace/merchant_onboarding/status/paypal').then((res) => {
      return res.data;
    })
  );
};

export const STRIPE_PAYMENT_INFO_QUERY_KEY = 'stripePaymentInfo';

// STRIPE
export const getStripePaymentInfo = () => {
  return useQuery(STRIPE_PAYMENT_INFO_QUERY_KEY, () =>
    sendGet('/marketplace/merchant_onboarding/status/stripe').then((res) => {
      return res.data;
    })
  );
};

export const postPaymentInfo = (params) => sendPost('/stripe_integrations/account', params);

export const patchPaymentInfo = (params) => sendPatch('/stripe_integrations/account', params);

export const removePayPalLinkedAccount = () => sendDelete('/marketplace/merchant');

export const useChannelInfo = (supplierOrderId) => {
  return useQuery(['fetchChatChannelInfo', supplierOrderId], () =>
    sendGet(`/chat_channels/${supplierOrderId}/info`).then((res) => {
      return res.data;
    })
  );
};
