import ApiCall from '../utils/apiCall';
import { parseRailsErrorsToString } from '../utils';

import { signInSupplier } from '../../src/services/api';

import {
  SIGN_IN_USER,
  SIGN_OUT_USER,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_USER,
  MESSAGE_SUCCESS,
  MESSAGE_ERROR,
  MESSAGE_CLEAR,
  OPEN_RESET_MODAL,
  CLOSE_RESET_MODAL,
  UPDATE_ORDERS,
  UPDATE_ACCOUNT_SETTINGS,
  FILE_UPLOADING,
  FILE_UPLOADED,
  GOT_LISTINGS_CATEGORIES
} from './constants';

function dispatchAuthError(dispatch, error) {
  if (error.status && error.json.error) {
    dispatch(authError({ error: parseRailsErrorsToString(error.json) }));
  } else {
    dispatch(authError({ error: 'Something went wrong' }));
  }
  return Promise.reject(error);
}

export function handleAuthError(dispatch) {
  return dispatchAuthError.bind(null, dispatch);
}

// Does everything needed to sign the user out.
function signOutHelper(dispatch) {
  clearAuthStore();
  dispatch(signOut());
}

export function signUpUser(credentials) {
  return function (dispatch) {
    return ApiCall.post('/', {
      name: credentials.name,
      store_name: credentials.store_name,
      email: credentials.email,
      password: credentials.password,
      password_confirmation: credentials.passwordConfirmation
    })
      .then(({ status, json }) => {
        dispatch(signIn());
        setupAuthStore(json);
        if (typeof window !== 'undefined') {
          window.location = '/';
        }
      })
      .catch(handleAuthError(dispatch));
  };
}

export function resetPassword(credentials) {
  return function (dispatch) {
    return ApiCall.get('/password/new', {
      email: credentials.email
    })
      .then(({ status, json }) => {
        dispatch(closeResetModal());
      })
      .catch(handleAuthError(dispatch));
  };
}

export function changePassword(params) {
  return function (dispatch) {
    return ApiCall.patch('/', params)
      .then(({ status, json }) => {
        signOutHelper(dispatch);
      })
      .catch(() => {
        setAlertMessage('Password does not match', 'error')(dispatch);
        handleAuthError(dispatch);
      });
  };
}

export function signInUser(credentials, router) {
  return function (dispatch) {
    return signInSupplier(credentials).then(({ status, data }) => {
      setupAuthStore(data);
      dispatch(signIn());
      dispatch(accountSettingsUpdated({ supplier: data.supplier }));
    });
  };
}

export function signOutUser() {
  return function (dispatch) {
    signOutHelper(dispatch);
  };
}

export function verify() {
  return function (dispatch) {
    if (localStorage.getItem('auth_token') != null) {
      return ApiCall.get('/verify')
        .then(({ status, json }) => {
          dispatch(auth(json));
        })
        .catch(({ status, json }) => {
          signOutHelper(dispatch);
        });
    }
    if (typeof window !== 'undefined') {
      window.location = '/login';
    }
  };
}

export function setAlertMessage(message, type, delay = false) {
  return function (dispatch) {
    switch (type) {
      case 'success':
        dispatch(messageSuccess(message, delay));
        break;
      case 'error':
        dispatch(messageError(message, delay));
        break;
      default:
        break;
    }
  };
}

export function clearAlertMessage() {
  return function (dispatch) {
    dispatch(messageClear());
  };
}

export function setupAuthStore(credentials) {
  const expiresAt = JSON.stringify(10800 + new Date().getTime());
  localStorage.setItem('expires', expiresAt);
  localStorage.setItem('auth_token', credentials.auth_token);
  localStorage.setItem('user_id', credentials.supplier.id);
  localStorage.setItem('user_name', credentials.supplier.name);
  localStorage.setItem('user_email', credentials.supplier.email);
}

export function clearAuthStore() {
  localStorage.removeItem('auth_token');
  localStorage.removeItem('user_id');
  localStorage.removeItem('user_name');
  localStorage.removeItem('user_email');
  localStorage.removeItem('expires_at');
}

function listingFetch(dispatch, data, method, urlSuffix = '') {
  data.images.concat(5);
  return ApiCall[method.toLowerCase()](`/supplier/listing${urlSuffix}`, data)
    .then(({ status, json }) => {
      switch (status) {
        case 200:
          dispatch(messageSuccess('Listing Updated Successfully', false));
          if (typeof window !== 'undefined') {
            window.location = '/products';
          }
          break;
        case 201:
          dispatch(messageSuccess('Listing Created Successfully', false));
          if (typeof window !== 'undefined') {
            window.location = '/products';
          }
          break;
        default:
          break;
      }
      return { status, json };
    })
    .catch((error) => {
      if (error.json) {
        let errorText = false;
        const { json } = error;
        const { status } = error;
        switch (status) {
          case 400:
            errorText = json.message;
            break;
          case 401:
            errorText = 'Authentication Error';
            break;
          case 422:
            for (const message in json.message) {
              if (Object.prototype.hasOwnProperty.call(json.message, message)) {
                dispatch(messageError(`${message} ${json.message[message]}`, false));
              }
            }
            break;
          default:
            throw error;
        }
        dispatch(messageError(errorText, false));
      } else {
        dispatch(messageError('Internal Error', false));
      }
      throw error;
    });
}

export function activateMultipleListings(active, listings) {
  const body = { ids: listings, active: true };
  return function (dispatch) {
    return ApiCall.patch('/supplier/listings/activate/', body)
      .then(({ status, json }) => {
        dispatch(messageSuccess(json.message, false));
      })
      .catch(({ status, json }) => {
        dispatch(messageError(json.message), false);
      });
  };
}

export function editListing(data, id) {
  return function (dispatch) {
    listingFetch(dispatch, data, 'PATCH', `/${id}`)
      .then(({ status, json }) => {
        // dispatch(productListingEdited(json))
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function createListing(data) {
  return function (dispatch) {
    listingFetch(dispatch, data, 'POST')
      .then(({ status, json }) => {
        // dispatch(productListingCreated(json))
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function uploadImage(file, url, fileCreatedCallback) {
  return function (dispatch) {
    const fileEndpoint = process.env.NEXT_PUBLIC_API_URL + url;
    const form = new FormData();

    if (!/(.*?)\.(png|jpg|jpeg)$/.test(file.name.toLowerCase())) {
      dispatch(messageError('Invalid file type. Must be PNG or JPEG.', false));
      return;
    }

    if (file.size > process.env.NEXT_PUBLIC_API_MAX_FILE_SIZE) {
      const mbLimit = process.env.NEXT_PUBLIC_API_MAX_FILE_SIZE / 1000000;
      dispatch(messageError(`File too large. Must be ${mbLimit.toFixed(0)}mb or smaller`, false));
      return;
    }

    form.append('file', file);
    const xhr = new XMLHttpRequest();
    const auth_token = localStorage.getItem('auth_token');
    xhr.open('POST', fileEndpoint);
    xhr.setRequestHeader('Authorization', `Bearer ${auth_token}`);

    xhr.send(form);
    dispatch(fileUploading());

    xhr.onload = function () {
      dispatch(fileUploaded());
      if (this.status >= 200 && this.status < 300) {
        const data = JSON.parse(this.response);
        fileCreatedCallback(data);
      } else {
        dispatch(messageError('There was an issue uploading your file.', false));
      }
    };

    xhr.onerror = function () {
      dispatch(messageError('There was an issue uploading your file.', false));
    };
  };
}

export function getOrders(page = 1, limit = 24) {
  return function (dispatch) {
    return ApiCall.get('/supplier/orders', { page, limit })
      .then(({ json }) => {
        dispatch(ordersUpdated(json));
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function cancelOrder(orderId, cancelReason) {
  return function (dispatch) {
    return ApiCall.post(`/orders/${orderId}/cancel`, {
      cancel_reason: cancelReason
    })
      .then(() => {
        dispatch(messageSuccess('Your order has been cancelled.', false));
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch(() => {
        dispatch(messageError('We were not able to cancel your order.'), false);
      });
  };
}

export function updateSettings(method, body) {
  return function (dispatch) {
    return ApiCall[method.toLowerCase()]('/settings', body)
      .then(({ status, json }) => {
        dispatch(accountSettingsUpdated(json));
        if (method !== 'GET') {
          dispatch(messageSuccess('Settings updated successfully', false));
        }
      })
      .catch(({ json }) => {
        if (method !== 'GET') {
          // handle return object in case it's a custom form invalidation response like the following:
          //  {
          //    "shipping_specifics": [
          //     {
          //         "0": "incremental price is greater than base"
          //     }
          //  ]
          // }
          Object.keys(json).forEach((error) => {
            if (Array.isArray(json[error])) {
              json[error].forEach((errorMessage) => {
                Object.keys(errorMessage).forEach((index) => {
                  dispatch(messageError(errorMessage[index], false));
                });
              });
            } else if (typeof json[error] === 'string') {
              // handle simple case when the error value for given key is a plain string
              dispatch(messageError(json[error], false));
            }
          });
        }
        dispatch(accountSettingsUpdated());
      });
  };
}

export function getListingsCategories() {
  return function (dispatch) {
    return ApiCall.get('/listings_category').then(({ status, json }) => {
      dispatch(gotListingCategpories(json));
    });
  };
}

// Action Creators below this point

export function fileUploading() {
  return {
    type: FILE_UPLOADING
  };
}

export function gotListingCategpories(payload) {
  return {
    type: GOT_LISTINGS_CATEGORIES,
    payload
  };
}

export function fileUploaded(fileUrl) {
  return {
    type: FILE_UPLOADED
  };
}

export function ordersUpdated(data) {
  return {
    type: UPDATE_ORDERS,
    orders: data.transactions,
    ordersCount: data.count
  };
}

export function accountSettingsUpdated(settings) {
  return {
    type: UPDATE_ACCOUNT_SETTINGS,
    settings
  };
}

export function openResetModal() {
  return {
    type: OPEN_RESET_MODAL
  };
}

export function closeResetModal() {
  return {
    type: CLOSE_RESET_MODAL
  };
}

export function signIn() {
  return {
    type: SIGN_IN_USER
  };
}

export function auth(json) {
  return {
    type: AUTH_USER,
    json
  };
}

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error
  };
}

export function authSuccess(success) {
  return {
    type: AUTH_SUCCESS,
    payload: success
  };
}

export function signOut() {
  return {
    type: SIGN_OUT_USER
  };
}

export function messageSuccess(message, delay) {
  return {
    type: MESSAGE_SUCCESS,
    payload: {
      message,
      delay,
      time: Date.now()
    }
  };
}

export function messageError(message, delay) {
  return {
    type: MESSAGE_ERROR,
    payload: {
      message,
      delay,
      time: Date.now()
    }
  };
}

export function messageClear() {
  return {
    type: MESSAGE_CLEAR
  };
}
