import ApiCall from '../../utils/apiCall';
import { messageError } from '../../actions';

const initialState = {
  isUpdating: false
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'registration.isFetching':
      return { ...state, isFetching: true };

    case 'registration.success':
      return { ...state, isFetching: false, ...action.response };

    case 'registration.fetchError':
      return { ...state, isFetching: false, ...action.response };

    default:
      return state;
  }
}

function payload(credentials) {
  return {
    phone_number: credentials.phoneNumber,
    secondary_contact_email: credentials.secondaryContactEmail,
    secondary_phone_number: credentials.secondaryPhoneNumber,
    default_migration_category_id: credentials.defaultMigrationCategoryId,
    discount_percentage: credentials.discountPercentage,
    processing_time: credentials.processingTime,
    default_return_policy: credentials.defaultReturnPolicy,
    default_return_window: credentials.defaultReturnWindow,
    sync_products: true,
    shipping_origin: credentials.shippingOrigin,
    manufacturing_origin: credentials.manufacturingOrigin,
    return_address: {
      city: credentials.returnAddressCity,
      country: credentials.returnAddressCountry,
      line_one: credentials.returnAddressLineOne,
      line_two: credentials.returnAddressLineTwo,
      state: credentials.returnAddressState,
      zip: credentials.returnAddressZip
    },
    default_domestic_shipping: {
      base_price_float: credentials.domesticShippingBasePrice,
      delivery_time: credentials.domesticShippingDeliveryTime,
      incremental_price_float: credentials.domesticShippingIncrementalPrice
    },
    default_international_shipping: {
      base_price_float: credentials.internationalShippingBasePrice,
      delivery_time: credentials.internationalShippingDeliveryTime,
      incremental_price_float: credentials.internationalShippingIncrementalPrice
    }
  };
}

// Actions
export function register(credentials, actions) {
  return (dispatch) => {
    dispatch({ type: 'registration.isFetching' });
    ApiCall.patch('/settings', payload(credentials))
      .then((response) => {
        dispatch({
          type: 'registration.success',
          response: response.json
        });
        if (typeof window !== 'undefined') {
          window.location = '/';
        }
      })
      .catch(({ status, json }) => {
        if (json instanceof TypeError) {
          Object.keys(json).forEach((error) => {
            dispatch(messageError(json[error], false));
            actions.setStatus(json[error]);
          });
        }
        actions.setSubmitting(false);
      });
  };
}
