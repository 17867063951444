import { createStore, compose, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';

import rootReducer from '../reducers/index';
import { signOutUser } from '../actions/index';
/* global $ */

/**
  Service to handle ajax requests to our main api (Hanami application)

  TODO:
  - Create Put, Post and Delete
 */
export default class ApiCall {
  static API_URL = process.env.NEXT_PUBLIC_API_URL;
  /**
   * Make a get request to our api (Hanami application)
   * @param  {String} url    path to resource without host
   * @param  {Hash} params   Query params
   * @return {Promise}
   */
  static get(url, params) {
    return fetch(this._mountUrlWithParams(url, params), {
      headers: this._buildHeaders()
    })
      .then(this._rejectBadResponse)
      .then(this._checkAuth)
      .then(this._jsonResponse)
      .catch((error) => {
        if (window.location.pathname !== '/maintenance' && !error.status) {
          window.location.replace('/maintenance');
        }
      });
  }

  /**
   * Post request with stringified body.
   * @param  {String} url
   * @param  {Hash} data
   * @return {Promise}
   */
  static post(url, data) {
    return fetch(this._mountUrlWithParams(url, {}), {
      method: 'POST',
      headers: this._buildHeaders(),
      body: JSON.stringify(data)
    })
      .then(this._rejectBadResponse)
      .then(this._checkAuth)
      .then(this._jsonResponse);
  }

  static patch(url, data) {
    return fetch(this._mountUrlWithParams(url, {}), {
      method: 'PATCH',
      headers: this._buildHeaders(),
      body: JSON.stringify(data)
    })
      .then(this._rejectBadResponse)
      .then(this._checkAuth)
      .then(this._jsonResponse);
  }

  /**
   * Make a delete request to our api
   * @param  {String} url    path to resource without host
   * @param  {Hash} params   Query params
   * @return {Promise}
   */
  static delete(url, params) {
    return fetch(this._mountUrlWithParams(url, params), {
      headers: this._buildHeaders(),
      method: 'DELETE'
    })
      .then(this._rejectBadResponse)
      .then(this._jsonResponse);
  }

  // private

  /**
   * Returns a rejected promise with a status and a message if the response
   * is invalid.
   * @param  {Response}
   * @return {Response} or new rejected promise
   */
  static _rejectBadResponse(response) {
    // Note: we might want to check for other statuses or conditions here
    if (response.status >= 500) {
      return Promise.reject({
        status: response.status,
        json: { message: response.body }
      });
    } else {
      return response;
    }
  }

  /**
   * Converts the response to json
   * @param  {Response}
   * @return {Object} status, json
   */
  static _jsonResponse(response) {
    if (response.status === 204) {
      const body = { status: response.status, json: {} };
      return Promise.resolve(body);
    } else {
      return response.json().then((json) => {
        const body = { status: response.status, json };
        if (response.ok) {
          return body;
        } else {
          return Promise.reject(body);
        }
      });
    }
  }

  /**
   * Check if the user is unauthorized and log them out if they are.
   * @param  {Response} response
   * @return {Promise}
   */
  static _checkAuth(response) {
    if (response.status === 401) {
      const store = createStore(rootReducer, compose(applyMiddleware(reduxThunk)));
      store.dispatch(signOutUser());

      return Promise.reject({
        status: response.status,
        json: { message: 'Your session has expired. Please log in.' }
      });
    } else {
      return response;
    }
  }

  /**
   * Simple mount url request with query params
   * @param  {String} url    [description]
   * @param  {Hash} params [description]
   * @return {String}        [description]
   */
  static _mountUrlWithParams(url, params = {}) {
    const queryParams = Object.keys(params)
      .map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
      })
      .join('&');

    return `${this.API_URL}${url}?${queryParams}`;
  }

  /**
   * Build headers for fetch request with autorizatoin token
   * @return {Headers}
   */
  static _buildHeaders() {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('content-type', 'application/json');
    headers.append('Authorization', `Bearer ${this._token()}`);
    return headers;
  }

  /**
   * Token for api JWT autorization
   * @return {String}
   */
  static _token() {
    return localStorage.getItem('auth_token');
  }
}

export class ApiBaseCall extends ApiCall {
  static API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;
}
