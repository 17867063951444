/* eslint-disable import/no-mutable-exports */
import { createStore, compose, applyMiddleware } from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import reduxThunk from 'redux-thunk';

import { verify, updateSettings } from '../src_old/actions/index';
import rootReducer from '../src_old/reducers/index';

const makeStore = (initialState) => {
  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(reduxThunk),
      process.env.NODE_ENV !== 'production' &&
        typeof window !== 'undefined' &&
        window.devToolsExtension
        ? window.devToolsExtension()
        : (f) => f
    )
  );
  if (typeof window !== 'undefined' && localStorage.getItem('auth_token')) {
    store.dispatch(verify());
    store.dispatch(updateSettings('GET'));
  }

  return store;
};

export const ReduxContextWrapper = createWrapper(makeStore, { debug: false });
