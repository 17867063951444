import { MESSAGE_SUCCESS, MESSAGE_ERROR, MESSAGE_CLEAR } from '../actions/constants';

const initialState = {
  message: '',
  messageType: ''
};

export default function action(state = initialState, action) {
  switch (action.type) {
    case MESSAGE_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        messageDelay: action.payload.delay,
        messageTime: action.payload.time,
        messageType: 'success'
      };
    case MESSAGE_ERROR:
      return {
        ...state,
        message: action.payload.message,
        messageDelay: action.payload.delay,
        messageTime: action.payload.time,
        messageType: 'error'
      };
    case MESSAGE_CLEAR:
      return {
        ...state,
        message: '',
        messageType: ''
      };
    default:
      return state;
  }
}
