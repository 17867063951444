export const SIGN_IN_USER = 'SIGN_IN_USER';
export const SIGN_OUT_USER = 'SIGN_OUT_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_USER = 'AUTH_USER';
export const MESSAGE_SUCCESS = 'MESSAGE_SUCCESS';
export const MESSAGE_ERROR = 'MESSAGE_ERROR';
export const MESSAGE_CLEAR = 'MESSAGE_CLEAR';
export const OPEN_RESET_MODAL = 'OPEN_RESET_MODAL';
export const CLOSE_RESET_MODAL = 'CLOSE_RESET_MODAL';
export const REGISTER_SHOP = 'REGISTER_SHOP';
export const DEREGISTER_SHOP = 'DEREGISTER_SHOP';
export const UPDATE_ORDERS = 'UPDATE_ORDERS';
export const UPDATE_ACCOUNT_SETTINGS = 'UPDATE_ACCOUNT_SETTINGS';
export const FILE_UPLOADING = 'FILE_UPLOADING';
export const FILE_UPLOADED = 'FILE_UPLOADED';
export const GOT_LISTINGS_CATEGORIES = 'GOT_LISTINGS_CATEGORIES';
