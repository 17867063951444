export const colors = {
  // primaries
  dark: '#081A51',
  white: '#FFFFFF',

  // purples
  purple01: '#EFECFF;',
  purple02: '#F6F5FF',
  purple03: '#F7F3FB',
  purple04: '#784DDD',
  purple05: '#8144E5',
  purple05_opacity10: '#f4effd', // no real opacity
  purple06_opacity25: '#9680FF40',
  purple07: '#9078FF',
  purple08: '#EEEBFF',
  purple09: '#CCC2FF',
  purple10: '#F7F6FB',
  purple20: '#E9E7FF',
  purple30: '#DCD7FF',
  purple40: '#6342FF',
  purple40_opacity30: '#6342FF4D',
  purple50: '#4228BE',
  purple60: '#571ABC',

  // Greens

  green01: '#F6FBF8',
  green02: '#70BA98',
  green03: '#D1FAE5',
  green10: '#44725D',
  green20: '#66CC76',
  green50: '#065F46',

  // Reds
  red00: '#FD57571A',
  red01: '#FFDFDF',
  red02: '#DF6161',
  red03: '#D43939',
  red04: '#FE504E',
  red05: '#D02F44',
  red06: '#FD5757',
  red10: '#B90000',
  red_input_error: '#FE0000',

  // Yellow

  yellow01: '#FEFAF3',
  yellow02: '#F5B756',
  yellow03: '#FBF8E5',
  yellow04: '#FAF7D1',
  yellow10: '#946E2D',
  yellow50: '#5F4D06',

  // Browns
  brown01: '#866E42',

  // Blues

  blue01: '#F6F9FB',
  blue02: '#16215B',
  blue03: '#EFF4F7',

  // greys
  grey01: '#DCDAE0',
  grey02: '#F0F0F0',
  grey03: '#E5E5E5',
  grey04: '#FAFBFC',
  grey05: '#F5F5F5',
  grey06: '#A9ADB3',
  grey07: '#F7F7F7',
  grey08: '#A2A2A2',
  grey09: '#E0E0E0',
  grey10: '#B8B8B8',
  grey11: '#DEDEDE',
  grey12: '#F2F2F2',
  grey13: '#D1D5DB',
  grey14: '#C4C4C4',
  grey15: '#F7F7F7',
  grey16: '#CCCCCC',
  grey17: '#A7AAAF',
  grey20: '#E9E6ED',
  grey30: '#081A51',
  grey31: '#88898C',
  grey40: '#322F4C',
  grey41: '#ccd2e1',
  grey50: '#E4EBEF',
  grey51: '#4B4B4B',
  grey60: '#E6E8F0',
  grey70: '#9DA5B8',
  grey80: '#5A5A5A',
  grey90: '#5D626D',
  greyDivider: '#ECE5E6',

  // blacks
  black01: '#081F40',
  black02: '#5A5A5A',
  black03: '#313131',
  black03_opacity60: '#31313199',
  black10: '#17384B',
  black15: '#2E3039',
  black20: '#222939',
  black30: '#202223',
  black: '#000000'

  // TODO add more colors as needed
  // if other repeat themes are wanted
  // ex: FONTS + font styled other export objects cant be made
};
