import {
  UPDATE_ACCOUNT_SETTINGS,
  FILE_UPLOADING,
  FILE_UPLOADED,
  GOT_LISTINGS_CATEGORIES
} from '../actions/constants';

const initialSettingsState = {
  settings: {},
  listingsCategories: [],
  showLoading: true
};

export default function settingsReducer(state = initialSettingsState, action) {
  switch (action.type) {
    case UPDATE_ACCOUNT_SETTINGS:
      return {
        ...state,
        settings: action.settings ? action.settings : state.settings,
        showLoading: false
      };
    case FILE_UPLOADING:
      return {
        ...state,
        uploadInProcess: true
      };
    case FILE_UPLOADED:
      return {
        ...state,
        uploadInProcess: false
      };
    case GOT_LISTINGS_CATEGORIES:
      return {
        ...state,
        listingsCategories: action.payload,
        showLoading: false
      };
    default:
      return state;
  }
}
