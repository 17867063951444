import { UPDATE_ORDERS } from '../actions/constants';

const initialOrdersState = { orders: [] };

export default function orderReducer(state = initialOrdersState, action) {
  switch (action.type) {
    case UPDATE_ORDERS:
      return {
        ...state,
        orders: action.orders,
        ordersCount: action.ordersCount
      };
    default:
      return state;
  }
}
