import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Context from './Context';

const ContextProvider = (props) => {
  const { children } = props;

  const [screenSize, setScreenSize] = useState(
    typeof window !== 'undefined'
      ? { screenWidth: window.innerWidth, screenHeight: window.innerHeight }
      : { screenWidth: 0, screenHeight: 0 }
  );

  const handleResize = () => {
    setScreenSize({ screenWidth: window.innerWidth, screenHeight: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return <Context.Provider value={{ screenSize }}>{children}</Context.Provider>;
};

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ContextProvider;
